<template>
  <div class="card">
    <h3 class="card-title">LISTAR SUCURSALES</h3>
    <div v-if="sucursales">
      <table-list
        ref="tablelist"
        :showFilter="true"
        :data="sucursales"
        :header="headers"
        :body="body"
        :actionButton="actionButton"
        @rowClicked="showSucursal"
      ></table-list>
    </div>
    <modal v-if="openNew" @close="openNew = false">
      <nueva-sucursal slot="modal-body" @saved="refresh"></nueva-sucursal>
    </modal>
  </div>
</template>
<script>
import { SucursalServices } from "../services/SucursalServices";
import TableList from "@/components/elements/TableList";
import Modal from "@/components/elements/Modal";
import NuevaSucursal from "./NuevaSucursal.vue";
export default {
  components: { TableList, Modal, NuevaSucursal },
  name: "ListarSucursales",
  data() {
    return {
      sucursales: null,
      headers: SucursalServices.headers(),
      body: SucursalServices.body(),
      actionButton: {
        text: "Nueva Sucursal",
        action: () => {
          this.openNew = true;
        },
      },
      openNew: false,
    };
  },
  mounted() {
    SucursalServices.api.getAll().then((sucursales) => (this.sucursales = sucursales));
  },
  methods: {
    refresh() {
      this.openNew = false;
      SucursalServices.api.getAll().then((sucursales) => {
        this.sucursales = sucursales;
        this.$refs['tablelist'].refresh(sucursales)
      });
    },
    showSucursal(sucursal){
      this.$router.push({name:'VerSucursal', params:{id:sucursal.id}})
    }
  },
};
</script>